.carousel-primary,
.carousel-secondary {
  top: 0;
  left: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.carousel-primary img {
  width: 100%;
  margin-left: 0px;
}

.carousel-primary {
  display: flex;
  justify-content: space-around;
  animation: scroll-horizontal 6s linear infinite;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.carousel-secondary {
  animation: scroll-horizontal 6s linear infinite;
  animation-delay: 3s;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

@keyframes scroll-horizontal {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.carousel-primary:hover,
.carousel-primary:hover ~ .carousel-secondary {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

.carousel-secondary {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

.scroll-container {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}
